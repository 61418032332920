/* eslint brace-style: ["error", "stroustrup"] */
import { useState, useEffect, useCallback } from 'react';
import './Confirmation.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as loglevel from 'loglevel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import env from '../../../env/env';
import Navbar from '../../sep_map/component_navbar/Navbar';
import HyperMenu from '../../hyper_menu/HyperMenu';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function Confirmation(props) {
  const { t } = useTranslation(['favorites', 'menu']);
  const [confirmResponse, setConfirmResponse] = useState(null);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const jwt = searchParams.get('jwt');
  const uri = searchParams.get('uri');

  const performRequest = useCallback(async () => {
    const endpoint = `${uri}`;
    const data = {};
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify(data),
      },
    )
      .then((response) => response)
      .catch((error) => {
        log.error('Confirmation confirmNotification', { error });
        throw error;
      });
  }, [jwt, uri]);

  const confirmNotification = useCallback(async () => {
    await performRequest()
      .then((res) => {
        setConfirmResponse(res);
      })
      .catch((error) => {
        setConfirmResponse(error.response);
      });
  }, [performRequest]);

  useEffect(() => {
    if (!!jwt && !!uri && !confirmResponse) {
      confirmNotification();
    }
  }, [jwt, uri, confirmResponse, confirmNotification]);

  return (
    <div className="favorites">
      <HyperMenu
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        content={(
          <div className="menu-content-wrapper">
            <Navbar isSearchDisplayed={false} />
            <Box
              sx={{ m: 2 }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              {/* eslint-disable-next-line react/no-unstable-nested-components */
                (() => {
                  if (!confirmResponse) {
                    return (
                      <h3 className="Confirmation">
                        {t('favorites:email-notification-confirmation-loading')}
                      </h3>
                    );
                  } if (confirmResponse && confirmResponse.status === 200) {
                    return (
                      <h3 className="Confirmation">
                        {t('favorites:email-notification-confirmation-success')}
                      </h3>
                    );
                  }
                  return (
                    <h3 className="Confirmation">
                      {t('favorites:email-notification-confirmation-error')}
                    </h3>
                  );
                })()
              }
              {!!confirmResponse && (
                <Button
                  variant="contained"
                  onClick={() => navigate('/')}
                >
                  {t('menu:button-home')}
                </Button>
              )}
            </Box>
          </div>
        )}
      />
    </div>
  );
}

export default Confirmation;
