import { IconButton } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CircularProgress from '@mui/material/CircularProgress';
import * as loglevel from 'loglevel';
import { useCallback } from 'react';
import { useLocation } from '../../contexts/location/location-context';
import env from '../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function MapCurrentLocationButton({ onPositionChange }) {
  const { permission, getLocation, startWatcher } = useLocation();

  const centerMapOnUserLocation = useCallback(() => {
    getLocation(
      (pos) => {
        onPositionChange(pos.coords);
        startWatcher();
      },
      (error) => {
        // eslint-disable-next-line no-alert
        alert(error.message);
      },
    );
  }, [getLocation, onPositionChange, startWatcher]);

  return (
    <>
      {(() => {
        if (permission === null) {
          return (
            <IconButton sx={{ p: 0 }} disabled>
              <CircularProgress size={18} />
            </IconButton>
          );
        }
        return (
          <IconButton
            onClick={centerMapOnUserLocation}
            sx={{ p: 0 }}
          >
            <MyLocationIcon />
          </IconButton>
        );
      })()}
    </>
  );
}
