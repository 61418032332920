import SEPMap from '../SEPMap'
import './SepMapRoute.css'
import Navbar from '../component_navbar/Navbar'
import HyperMenu from '../../hyper_menu/HyperMenu'


import * as loglevel from "loglevel";
import env from "../../../env/env";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN)

export default function SepMapRoute (props) {

  return (
    <div className="Map">
      <HyperMenu
        {...props}
        content={
          (
            <div className="SepMapRoute">
              <Navbar isSearchDisplayed={true} />
              <SEPMap
                {...props}
              />
            </div>
          )
        }
      />
    </div>
  )
}
