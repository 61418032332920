import './Navbar.css';
import * as loglevel from 'loglevel';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/material';
import Search from '../component_search/Search';
import env from '../../../env/env';
import { useGlobalState } from '../../../contexts/global/GlobalStateProvider';
import { themes } from '../../mui/themes';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function Navbar(props) {
  const { isSearchDisplayed } = props;
  const { isNavOpen, setIsNavOpen } = useGlobalState();

  return (
    <div className="Navbar">
      <div className="navbar-container">
        <Stack
          className="bars"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '46.28px',
            width: '48px',
            cursor: 'pointer',
            backgroundColor: isNavOpen ? 'var(--gi_color_primary_color)' : themes.geoimpact.palette.primary.main,
            '&:hover': {
              backgroundColor: 'var(--gi_color_accent_color)',
            },
          }}
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <MenuIcon sx={{ color: 'white' }} />
        </Stack>
        {isSearchDisplayed && (
          <div className="input-wrapper">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Search {...props} />
          </div>
        )}
      </div>
    </div>
  );
}
