/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment */
import { useEffect, useState } from 'react';
import './UserManagement.css';
import { Navigate, useParams } from 'react-router-dom';
import * as loglevel from 'loglevel';
import SignUp from './sign_up/SignUp';
import AccountConfirm from './account_confirm/AccountConfirm';
import HyperMenu from '../hyper_menu/HyperMenu';
import Navbar from '../sep_map/component_navbar/Navbar';

import SignIn from './sign_in/SignIn';
import EditProfile from './edit/EditProfile';
import Recover from './recover/Recover';
import Resend from './sign_up/resend/Resend';
import Reset from './recover/reset/Reset';
// eslint-disable-next-line import/no-cycle
import { ProtectedRoute } from '../router/Router';
import UserAccount from './account/UserAccount';
import env from '../../env/env';

const PAGE_SIGNUP = 'signup';
const PAGE_SIGNUP_RESEND = 'signup-resend';
const PAGE_RESOURCES = 'account';
const PAGE_EDIT_USER = 'edit';
const PAGE_RECOVER_ACCOUNT = 'recover';
const ACCOUNT_CONFIRM = 'account-confirm';
const ACCOUNT_PASSWORD_RESET = 'recovery';
const SIGN_IN = 'signin';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

export default function UserManagement(props) {
  const params = useParams();
  const [page, setPage] = useState(null);
  useEffect(() => {
    // if page changes update
    if (
      params?.page !== page
    ) {
      setPage(params?.page);
    }
  }, [page, params?.page]);
  const getComponent = (myPage) => {
    switch (myPage) {
      case PAGE_SIGNUP:
        return (
          <SignUp />
        );
      case ACCOUNT_CONFIRM:
        return (
          <AccountConfirm {...props} />
        );
      case SIGN_IN:
        return (
          <SignIn {...props} />
        );
      case PAGE_EDIT_USER:
        return (
          <ProtectedRoute
            redirectComponent={<Navigate to="/user/signin/?continuePath=/user/edit/" />}
            {...props}
          >
            <EditProfile {...props} />
          </ProtectedRoute>
        );
      case PAGE_RESOURCES:
        return (
          <ProtectedRoute
            redirectComponent={<Navigate to="/user/signin/?continuePath=/user/account/" />}
            {...props}
          >
            <UserAccount />
          </ProtectedRoute>
        );
      case PAGE_RECOVER_ACCOUNT:
        return (
          <Recover />
        );
      case PAGE_SIGNUP_RESEND:
        return (
          <Resend />
        );
      case ACCOUNT_PASSWORD_RESET:
        return (
          <Reset {...props} />
        );
      case null:
        return null;
      default:
        throw new Error('Unknown user management page.');
    }
  };
  return (
    <div className="UserManagement">
      <HyperMenu
        {...props}
        content={(
          <div className="menu-content-wrapper">
            <Navbar isSearchDisplayed={false} />
            {getComponent(page)}
          </div>
            )}
      />
    </div>
  );
}
