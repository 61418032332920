import axios from "axios";
import env from "../../../env/env";

const workspaceId = '15c6c681-0671-4476-a6c0-6148d7aee2b0';

export async function fetchCollections(POSTMAN_API_KEY) {
  try {
    const response = await axios.get(`https://api.getpostman.com/collections?workspace=${workspaceId}`, {
      headers: {
        'X-Api-Key': POSTMAN_API_KEY,
      },
    });
    return response.data.collections;
  } catch (error) {
    console.error('Error fetching collections:', error);
    return [];
  }
}

export async function fetchCollectionDetails(collectionUid, POSTMAN_API_KEY) {
  try {
    const response = await axios.get(`https://api.getpostman.com/collections/${collectionUid}`, {
      headers: {
        'X-Api-Key': POSTMAN_API_KEY,
      },
    });
    return response.data.collection;
  } catch (error) {
    console.error(`Error fetching collection ${collectionUid}:`, error);
    return null;
  }
}

export async function fetchAPIEndpoints(url, jwt) {
  try {
    const response = await axios({
      url: `${env.API_GATEWAY_BASE}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'SEP-auth-override': jwt,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching API endpoints:', error);
    return [];
  }
}

export async function fetchCatalogData(bbApiToken) {
  try {
    const response = await axios({
      url: "https://api.bitbucket.org/2.0/repositories/geoimpactteam/market-senses/src/main/api.json",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${bbApiToken}`
      }
    });
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getPanelSettings(jwt) {
  try {
    const response = await axios({
        url: `${env.API_GATEWAY_BASE}/api/panelsettings-all`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'SEP-auth-override': jwt
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching panel settings:', error);
    return [];
  }
}

export async function getTenantLeads(jwt, campaignId) {
  try {
    const response = await axios({
        url: `${env.API_GATEWAY_BASE}/api/synchronize-full-leads?campaign=${campaignId}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'SEP-auth-override': jwt
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tenant leads:', error);
    return [];
  }
}
