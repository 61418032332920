import React, {
  useState, useEffect, useRef, useMemo, useContext,
  useCallback,
} from 'react';
import './HyperMenu.css';
import { Button } from 'semantic-ui-react';
import { Translation, useTranslation } from 'react-i18next';
import axios from 'axios';
import * as loglevel from 'loglevel';
import { useNavigate, useLocation } from 'react-router-dom';
import packageJson from '../../../package.json';

import { UserUtil } from '../user_management/util/user-util';

import env from '../../env/env';
import LanguageSwitch from '../settings/language_switch/LanguageSwitch';
import SupportRequest from '../reusable/SupportRequest';
import SEPContext from '../../contexts/sep-context/SEPContext';
import { useFavorites } from '../../contexts/favorites/FavoriteProvider';
import { useGlobalState } from '../../contexts/global/GlobalStateProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function MyFavorites(props) {
  const { onClick, active } = props;
  const { t } = useTranslation('menu');
  const { counter } = useFavorites();

  return (
    <div className="my-favorites">
      <div
        className="item"
      >
        <Button
          onClick={onClick}
          className="menu-button"
          primary
          active={active}
        >
          {t('button-my-favorites')}
          {counter > 0 && ` (${counter})`}
        </Button>
      </div>
    </div>
  );
}

function MyEvents(props) {
  const { onClick, active } = props;
  const updateEeventName = 'events-update';
  const favoritesUpdateEventName = 'favorites-update';
  const hookName = 'MyEvents';
  const { t } = useTranslation('events');
  const [isLoading, setIsLoading] = useState(false);
  const [eventsCount, setEventsCount] = useState([]);
  const cancelTokensRef = useRef([]);
  const { user: { jwt } } = useContext(SEPContext).SEPContext;

  const cancelRequests = () => {
    cancelTokensRef.current.forEach((cToken) => {
      try {
        cToken.source.cancel('Operation canceled by the user.');
      } catch (e) {
        log.error(`${hookName} cancelRequests - error`, { cToken });
      }
    });
    cancelTokensRef.current = [];
  };

  const getAddressEventsCount = async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/eventsforaddresses-count`;
    /* let data = {} */
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const request = axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        cancelToken: source.token,
      },
    )
      .then((response) => response)
      .catch((error) => {
        log.error(`${hookName} getAddressEventsCount`, { error });
      });
    cancelTokensRef.current.push({ request, source, CancelToken });
    return request;
  };

  const getRegionEventsCount = async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/eventsforregions-count`;
    /* let data = {} */
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const request = axios({
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      cancelToken: source.token,
    })
      .then((response) => response)
      .catch((error) => {
        log.error(`${hookName} getRegionEventsCount`, { error });
      });
    cancelTokensRef.current.push({ request, source, CancelToken });
    return request;
  };

  const fetchEvents = async () => {
    setIsLoading(true);
    const addressResponse = await getAddressEventsCount();
    const regionResponse = await getRegionEventsCount();
    setIsLoading(false);
    if (regionResponse && addressResponse) {
      const totalCount = addressResponse.data.count + regionResponse.data.count;
      setEventsCount(totalCount);
    }
  };

  useEffect(() => {
    fetchEvents();
    return () => {
      cancelRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onUpdate = () => {
      fetchEvents();
    };
    document.body.addEventListener(updateEeventName, onUpdate);
    return () => {
      // clenaup
      document.body.removeEventListener(updateEeventName, onUpdate);
      cancelRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onUpdate = () => {
      fetchEvents();
    };
    document.body.addEventListener(favoritesUpdateEventName, onUpdate);
    return () => {
      // clenaup
      document.body.removeEventListener(favoritesUpdateEventName, onUpdate);
      cancelRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCounter = () => {
    if (eventsCount > 0) {
      return `(${eventsCount})`;
    } return null;
  };

  return (
    <div className="my-favorites">
      <div
        className="item"
      >
        <Button
          loading={isLoading}
          onClick={onClick}
          className="menu-button"
          primary
          active={active}
        >
          {t('button-my-events')}
          {' '}
          {getCounter()}
        </Button>
      </div>
    </div>
  );
}

function HyperMenu(props) {
  const { isNavOpen, setIsNavOpen } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();

  const context = useContext(SEPContext).SEPContext;
  const [menuItems, setMenuItems] = useState(null);

  const thisRef = useMemo(() => ({
    props,
  }), [props]);
  const isActiveLink = useCallback((path) =>
    // eslint-disable-next-line react/destructuring-assignment,implicit-arrow-linebreak
    location.pathname === path, [location.pathname]);
  const getMenuHeightOffset = () => {
    const navbar = document.querySelectorAll('.navbar-container')[0];
    if (navbar) {
      return navbar.clientHeight;
    }
    return 0;
  };
  const getLanguageSwitch = useCallback(() => (
    <div
      className="language-switch"
      style={{
        marginBottom: '34px',
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <LanguageSwitch {...thisRef} />
    </div>
  ), [thisRef]);
  const getFooter = useCallback(() => (
    <div className="item centered-all">
      <div>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="version"
          onClick={() => {
            window.open('https://www.swissenergyplanning.ch', '_blank');
          }}
        >
          <img
            className="image"
            src={`${env.AZURE_STORAGE_BASE}/public/logo/sep/logo-sep-bw-transparent-bg.png`}
            alt="logo of swiss energy planning."
          />
          <div
            className="version"
          >
            v.
            {packageJson.version}
          </div>
        </div>
      </div>
    </div>
  ), []);
  const getProfileMenuItem = useCallback((t) => {
    if (!!context?.user?.jwt === true && context.user?.jwtParsed?.sub !== undefined) {
      return (
        <div
          className="item profile"
        >
          <Button
            onClick={() => {
              setIsNavOpen(false);
              navigate('/user/edit/');
            }}
            className="menu-button"
            primary
            active={!!isActiveLink('/user/edit/')}
          >
            <h3>
              {t('button-profile')}
            </h3>
            <div>
              {context.user?.jwtParsed?.sub}
            </div>
          </Button>
        </div>
      );
    }
    return null;
    // eslint-disable-next-line max-len
  }, [context.user?.jwt, context.user?.jwtParsed?.sub, isActiveLink, navigate, setIsNavOpen]);

  const getMenuItems = useCallback(() => {
    const isGuest = UserUtil.hasClientAndRole(context, 'guest', 'Guest');
    const isUser = UserUtil.hasRole(context, 'User');
    const getMenuComponent = (t) => {
      const signoutButtonComponent = (
        <Button
          onClick={() => {
            // eslint-disable-next-line react/destructuring-assignment
            props.onLogout();
          }}
          className="menu-button"
          primary
          active={false}
        >
          {t('button-signout')}
        </Button>
      );
      const getMyMenuItems = (commonItems) => {
        let menuComponent;
        const isLoggedIn = (
          !!context.user?.jwt === true
        );
        if (!isLoggedIn) {
          menuComponent = (
            <div className="scrollable-items">
              <div
                className="item nav-home"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    navigate('/');
                  }}
                  className="menu-button"
                  primary
                  active={!!isActiveLink('/')}
                >
                  {t('button-home')}
                </Button>
              </div>
              <div
                className="item nav-sign-in"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    navigate('/user/signin/', { unauthorized: false });
                  }}
                  className="menu-button"
                  primary
                  active={!!isActiveLink('/user/signin/')}
                >
                  {t('button-signin')}
                </Button>
              </div>
              <div
                className="item nav-sign-out"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    navigate('/user/signup/');
                  }}
                  className="menu-button"
                  primary
                  active={!!isActiveLink('/user/signup/')}
                >
                  {t('button-signup')}
                </Button>
              </div>
              <div
                className="item nav-about-sep"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    window.open('https://www.swissenergyplanning.ch/', '_blank');
                  }}
                  className="menu-button"
                  primary
                  active={!!isActiveLink('/what-is-sep/')}
                >
                  {t('button-what-is-sep')}
                </Button>
              </div>
              <div
                className="item nav-tutorials"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    window.open('https://www.youtube.com/channel/UCz36vttIW5WahMgiphnrGog', '_blank');
                  }}
                  className="menu-button"
                  primary
                  active={false}
                >
                  {t('button-tutorials')}
                </Button>
              </div>
              <div
                className="item nav-impress"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/impressum`, '_blank');
                  }}
                  className="menu-button"
                  primary
                  active={!!isActiveLink('/impressum/')}
                >
                  {t('button-impressum')}
                </Button>
              </div>
              <div
                className="item nav-privacy-policy"
              >
                <Button
                  onClick={() => {
                    setIsNavOpen(false);
                    window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank');
                  }}
                  className="menu-button"
                  primary
                  active={!!isActiveLink('/privacy/')}
                >
                  {t('button-privacy')}
                </Button>
              </div>
              {commonItems}
            </div>
          );
        } else if (
          isLoggedIn && (isGuest || isUser)
        ) {
          menuComponent = (
            <>
              {getProfileMenuItem(t)}
              <div className="scrollable-items">
                <div
                  className="item nav-home"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      navigate('/');
                    }}
                    className="menu-button"
                    primary
                    active={!!isActiveLink('/')}
                  >
                    {t('button-home')}
                  </Button>
                </div>
                <div
                  className="item nav-map"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      navigate('/map/');
                    }}
                    className="menu-button"
                    primary
                    active={!!isActiveLink('/map/')}
                  >
                    {t('button-map')}
                  </Button>
                </div>
                <MyFavorites
                  /* These components will display a "license-needed message" */
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...thisRef}
                  active={!!isActiveLink('/favorites/')}
                  onClick={() => {
                    setIsNavOpen(false);
                    navigate('/favorites/');
                  }}
                />
                <MyEvents
                  /* These components will display a "license-needed message" */
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...thisRef}
                  active={!!isActiveLink('/events/')}
                  onClick={() => {
                    setIsNavOpen(false);
                    navigate('/events/');
                  }}
                />
                <div
                  className="item nav-resources"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      navigate('/user/account/');
                    }}
                    className="menu-button"
                    primary
                    active={!!isActiveLink('/user/account/')}
                  >
                    {t('button-account')}
                  </Button>
                </div>
                <div
                  className="item nav-sign-out"
                >
                  {signoutButtonComponent}
                </div>
                <div
                  className="item nav-about-sep"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      window.open('https://www.swissenergyplanning.ch/', '_blank');
                    }}
                    className="menu-button"
                    primary
                    active={!!isActiveLink('/what-is-sep/')}
                  >
                    {t('button-what-is-sep')}
                  </Button>
                </div>
                <div
                  className="item nav-tutorials"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      window.open('https://www.youtube.com/channel/UCz36vttIW5WahMgiphnrGog', '_blank');
                    }}
                    className="menu-button"
                    primary
                    active={false}
                  >
                    {t('button-tutorials')}
                  </Button>
                </div>
                <div
                  className="item nav-impress"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/impressum`, '_blank');
                    }}
                    className="menu-button"
                    primary
                    active={!!isActiveLink('/impressum/')}
                  >
                    {t('button-impressum')}
                  </Button>
                </div>
                <div
                  className="item nav-privacy-policy"
                >
                  <Button
                    onClick={() => {
                      setIsNavOpen(false);
                      window.open(`https://${thisRef.props.i18n.language.split('-')[0]}.swissenergyplanning.ch/datenschutz`, '_blank');
                    }}
                    className="menu-button"
                    primary
                    active={!!isActiveLink('/privacy/')}
                  >
                    {t('button-privacy')}
                  </Button>
                </div>
                {commonItems}
              </div>
            </>
          );
        }
        return menuComponent;
      };
      return (
        <div className="item-container">
          {getMyMenuItems((
            <div
              className="item nav-cookie-settings"
            >
              <Button
                onClick={() => {
                  try {
                    window.CookieScript.instance.show();
                  } catch (e) {
                    // eslint-disable-next-line no-alert
                    window.alert(`Cookie-Script settings work only on Production environment. ERROR: ${e}`);
                  }
                }}
                className="menu-button"
                primary
              >
                {t('button-cookie-settings')}
              </Button>
            </div>
          ))}
          {getLanguageSwitch()}
          <SupportRequest />
          {getFooter()}
        </div>
      );
    };
    return (
      <Translation ns={['menu', 'events']}>
        {(t) => getMenuComponent(t)}
      </Translation>
    );
  }, [
    context,
    getFooter,
    getLanguageSwitch,
    getProfileMenuItem,
    isActiveLink,
    navigate,
    props,
    setIsNavOpen,
    thisRef,
  ]);

  useEffect(() => {
    setMenuItems(getMenuItems());
  }, [getMenuItems]);

  return (
    <div className="HyperMenu">
      <div
        className={`menu menu-${isNavOpen ? 'visible' : 'invisible'}`}
        style={(() => {
          if (isNavOpen) {
            return {
              top: `${getMenuHeightOffset()}px`,
              left: '0px',
              WebkitTransform: 'none',
              transform: 'none',
              transition: 'transform 1000ms ease-out',
              willChange: 'transform',
            };
          }
          const val = Math.min(320, window.screen.width);
          return {
            top: `${getMenuHeightOffset()}px`,
            left: '0px',
            WebkitTransform: `translateX(${-val}px)`,
            transform: `translateX(${-val}px)`,
            transition: 'transform 1000ms ease-in',
            willChange: 'transform',
          };
        })()}
      >
        {menuItems}
      </div>
      <div className="menu-content">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {props.content}
      </div>
    </div>
  );
}

export default HyperMenu;
