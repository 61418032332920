import {
  createContext, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';

import * as loglevel from 'loglevel';
import env from '../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const LocationCtx = createContext(null);

export default function LocationProvider({ children }) {
  const [positions, setPositions] = useState([]);
  const [permission, setPermission] = useState('unknown');

  const watchIdRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (typeof navigator !== 'undefined' && navigator.permissions) {
        try {
          const result = await navigator.permissions.query({ name: 'geolocation' });
          if (isMounted) setPermission(result.state);
          result.onchange = () => {
            if (isMounted) setPermission(result.state);
          };
        } catch {
          if (isMounted) setPermission('unknown');
        }
      } else if (isMounted) {
        setPermission('unknown');
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const getLocation = useCallback((onSuccess = () => {}, onError = () => {}) => {
    if (!navigator?.geolocation) {
      onError(new Error('Geolocation is not supported by this browser.'));
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setPositions((prev) => [...prev, pos]);
        onSuccess(pos);
      },
      (error) => {
        onError(error);
      },
    );
  }, []);

  const startWatcher = useCallback((onSuccess = () => {}, onError = () => {}) => {
    if (!navigator?.geolocation) {
      onError(new Error('Geolocation is not supported by this browser.'));
      return null;
    }

    if (watchIdRef.current) {
      log.warn('Location watching is already started.');
      return watchIdRef.current;
    }

    const id = navigator.geolocation.watchPosition(
      (pos) => {
        setPositions((prev) => [...prev, pos]);
        onSuccess(pos);
      },
      (error) => {
        onError(error);
      },
    );

    watchIdRef.current = id;
    return id;
  }, []);

  const stopWatcher = useCallback((id) => {
    const watchIdToClear = id || watchIdRef.current;
    if (watchIdToClear && navigator?.geolocation) {
      navigator.geolocation.clearWatch(watchIdToClear);
      if (!id) watchIdRef.current = null;
    }
  }, []);

  const api = useMemo(() => ({
    positions,
    permission,
    getLocation,
    startWatcher,
    stopWatcher,
  }), [positions, permission, getLocation, startWatcher, stopWatcher]);

  return (
    <LocationCtx.Provider value={api}>{children}</LocationCtx.Provider>
  );
}

export const useLocation = () => {
  const ctx = useContext(LocationCtx);
  if (!ctx) {
    throw new Error('useLocation must be used inside a the LocationProvider');
  }
  return ctx;
};
