import * as loglevel from 'loglevel';
import {
  createContext, useContext, useMemo, useState,
} from 'react';
import env from '../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const GlobalContext = createContext(null);

export default function GlobalStateProvider({ children }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const api = useMemo(() => ({
    isNavOpen,
    setIsNavOpen,
  }), [isNavOpen, setIsNavOpen]);

  return (
    <GlobalContext.Provider value={api}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
